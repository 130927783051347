.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#navbar-container {
  background: rgba(49, 35, 34);
  position: fixed;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  z-index: 100;
}

.scrolled {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  border-top: 0;
  transition: 0.3s;
}

.scrolled + #about-me-content {
  padding-top: 60px;
}

#navbar-container-left {
  /* align-self: flex-start; */
  margin: auto;
}

#navbar-container-right {
  /* align-self: flex-end; */
  margin: auto;
}

.navbar-links {
  list-style: none;
  display: flex;
  /* margin: 0; */
}

.navbar-links li {
  margin-left: 5vw;
}

/* these are to remove text decorations from all the links in the navigation bar and to style the text in them*/
.navbar-links a {
  text-decoration: none;
  color: rgb(253, 236, 236);
}

#navbar-logo {
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgb(253, 236, 236);
}

.parallax {
  /* The image used */
  background-image: url('./assets/dusan-veverkolog-UOS0NMcmURc-unsplash.jpg');

  /* Full height */
  height: 100vh;

  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

#about-me-content {
  background: rgba(73, 70, 67, 0.507);
  color: rgb(253, 236, 236);
  padding: 2rem;

  /* following is for making the block in center of the screen */
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  -moz-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}

#my-name {
  /* for removing font-wrapping */
  white-space: nowrap;
  color: rgb(255, 255, 255);
}

#introduction {
  display: flex;
  justify-content: center;
}

#my-image {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;

  /* to make it circular */
  border-radius: 50%;
}

.skill-text {
  margin-left: 1rem;
  margin-right: 1rem;
}

.skill-icon {
  /* had to use flex to make icon center with the border */
  display: flex;
  justify-content: center;

  border: solid 3px rgb(195, 184, 183);
  border-radius: 50%;
  padding: 1rem;
  height: 2rem;
  width: 2rem;
}

.skill-intermediate {
  color: #ff77db;
}

.skill-advanced {
  color: #a479ff;
}

#skillset {
  display: flex;
  justify-content: center;
  text-align: center;
  background-color: rgb(235, 235, 235);
}

#contact {
  display: flex;
  justify-content: center;
  background-color: rgb(235, 235, 235);
}

#projects {
  display: flex;
  justify-content: center;
}

#projects b {
  color: rgb(255, 102, 0);
  /* font-size: larger; */
}

#projects a {
  /* text-decoration: none; */
  font-weight: bold;
  color: rgb(15, 159, 255);
}

.not-expanded {
  display: none !important;
  /* transform: translateY(-100%) !important;
  transition: all 0.2s ease-out; */
}

/* .burg-links {
  transform: translateY(0%);
} */

/* 
.expanded {
  height: 100%;
  width: 100%;
  visibility: visible;
 
} */

#burgButton {
  color: rgb(253, 236, 236);
}
